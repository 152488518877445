<script setup lang="ts">
import type { HomeBanners, FirstScreenBanners } from "@/types";

const {
	gamesWithWeekGame,
	hotGames,
	lastGames,
	jackpotGames,
	isShowAdventureBanner,
	banners,
	jackpots,
	seoData,
	newGames,
	holdNLinksGames,
	topGames,
	fruitsGames,
	wildGames,
	bonusBuy,
	bonusCombo
} = useHomePage();
const { isMobile } = useDevice();

useHead({
	title: () => seoData.value?.seoTitle || "",
	meta: [
		{
			name: "description",
			content: () => seoData.value?.seoDescription
		},
		{ name: "keywords", content: () => seoData.value?.seoKeywords || "" }
	]
});

const isGuest = useIsGuest();

const { t } = useT();
const { data: appInitData } = useAppInitData();
</script>
<template>
	<div :class="['page', { 'is-logged': !isGuest }]">
		<LazyOHomeBanners v-if="banners?.length && banners.length > 0" :banners="banners as FirstScreenBanners" />
		<LazyOHomeGames
			v-if="lastGames?.length && !isGuest"
			:games="lastGames"
			:title="t('Recently Played')"
			icon="home/recent"
			category="recent-games"
			:rows-per-slide="1"
		/>

		<template v-if="gamesWithWeekGame?.length">
			<OLazyComponent v-if="isGuest && isMobile" id="home-game-guest-must-try" :height="492">
				<LazyOHomeGames
					:games="gamesWithWeekGame"
					:title="t('Must try')"
					icon="home/must-try"
					category="popular-games"
					:rows-per-slide="3"
				/>
			</OLazyComponent>
			<LazyOHomeGames
				v-else
				:games="gamesWithWeekGame"
				:title="t('Must try')"
				icon="home/must-try"
				category="popular-games"
				:rows-per-slide="3"
			/>
		</template>

		<OLazyComponent id="new-games">
			<LazyOHomeGames
				v-if="newGames?.length && newGames.length > 0"
				:games="newGames"
				:title="t('New games')"
				icon="home/new"
				category="new-games"
				:rows-per-slide="2"
			/>
		</OLazyComponent>

		<OLazyComponent id="home-game-guest-must-try">
			<LazyOHomeActivityFeed />
		</OLazyComponent>

		<OLazyComponent id="home-promotions">
			<OHomePromotions />
		</OLazyComponent>

		<OLazyComponent v-if="isGuest" id="banner-wheel" class="wheels-banners">
			<LazyOHomeBannerWheel v-if="appInitData?.isShowDailyWheelForGuest" />
			<LazyOHomeBannerAdventure v-if="isShowAdventureBanner" />
		</OLazyComponent>

		<OLazyComponent id="top-games">
			<lazyOHomeGames
				v-if="topGames?.length && topGames.length > 0"
				:games="topGames"
				:title="t('Monthly TOP')"
				icon="home/top"
				category="top-games"
				:rows-per-slide="1"
			/>
		</OLazyComponent>

		<OLazyComponent id="bonus-buy-games">
			<LazyOHomeGames
				v-if="bonusBuy?.length && bonusBuy.length > 0"
				:games="bonusBuy"
				:title="t('Bonus Buy')"
				icon="home/bonus-buy"
				category="bonus-buy"
				:rows-per-slide="2"
			/>
		</OLazyComponent>

		<OLazyComponent id="home-games">
			<LazyOHomeGames
				v-if="hotGames?.length && hotGames.length > 0"
				:games="hotGames"
				:title="t('Hot games')"
				icon="home/hot"
				category="hot-games"
				:rows-per-slide="2"
			/>
		</OLazyComponent>

		<OLazyComponent v-if="jackpots" id="home-jackpot">
			<LazyOHomeJackpot :game-mode="appInitData?.gameMode" :jackpots="jackpots" :games="jackpotGames" />
		</OLazyComponent>

		<OLazyComponent v-if="isGuest" id="home-why-us">
			<LazyOHomeWhyUs />
		</OLazyComponent>

		<OLazyComponent id="bonus-combo-games">
			<LazyOHomeGames
				v-if="bonusCombo?.length && bonusCombo.length > 0"
				:games="bonusCombo"
				:title="t('Bonus Combo')"
				icon="home/bonus-combo"
				category="bonus-combo"
				:rows-per-slide="1"
			/>
		</OLazyComponent>

		<OLazyComponent id="hold-n-link-games">
			<LazyOHomeGames
				v-if="holdNLinksGames?.length && holdNLinksGames.length > 0"
				:games="holdNLinksGames"
				:title="t('Hold`N`Link')"
				icon="home/hold-n-link"
				category="hold-n-link"
				:rows-per-slide="2"
			/>
		</OLazyComponent>

		<OLazyComponent id="wild-life-games">
			<LazyOHomeGames
				v-if="wildGames?.length && wildGames.length > 0"
				:games="wildGames"
				:title="t('Wild Life')"
				icon="home/wild"
				category="wild-life"
				:rows-per-slide="2"
			/>
		</OLazyComponent>

		<OLazyComponent id="fruits-games">
			<LazyOHomeGames
				v-if="fruitsGames?.length && fruitsGames.length > 0"
				:games="fruitsGames"
				:title="t('Fruits')"
				icon="home/fruits"
				category="fruits-games"
				:rows-per-slide="2"
			/>
		</OLazyComponent>

		<OLazyComponent v-if="isGuest && banners?.length && banners.length > 0" id="banner-footer">
			<LazyOHomeBannerFooter :banner="banners?.[0] as HomeBanners[number]" />
		</OLazyComponent>
	</div>
</template>

<style lang="scss" scoped>
.page {
	display: flex;
	flex-direction: column;

	@include media-breakpoint-down(md) {
		gap: 32px;

		&.is-logged {
			position: relative;
			padding-top: 8px;
		}
	}

	&:deep(.lazy-skeleton) {
		margin-top: 16px;
	}
	&.is-logged #fruits-games {
		margin-bottom: 32px;
	}
}

.wheels-banners {
	display: flex;
	flex-direction: column;
	gap: 32px;
	padding: 24px 0;

	@include media-breakpoint-down(md) {
		padding: 20px;
	}
}
</style>
